import Swiper from 'swiper';

const swiper = (container = '.swiper-container', opts = {
  speed: 300,
  effect: 'slide',
  slidesPerView: 1,
  autoplay: { delay: 5000 },
  spaceBetween: 45,
  loop: true,
  keyboard: { enabled: true },
  grabCursor: true,
  pagination: { el: '.swiper-pagination', type: 'bullets', clickable: true },
  navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
}) => new Swiper(container, opts);


export default swiper;
