/* global FORM_URL, ENV */

const formHandler = () => ({
  submitReady: true,
  isLoading: false,
  isSuccess: false,
  isError: false,
  formError: false,
  submitForm(el) {
    // Display loading screen
    this.isLoading = true;
    this.submitReady = false;

    // Prepare form content
    const form = new FormData(el);
    const request = new Request(process.env.FORM_URL, {
      method: 'POST',
      body: form,
      // Don't automatically redirect
      redirect: 'manual',
    });

    this.isLoading = true;

    setTimeout(() => {
      // Check if form is complete
      if (!(el.checkValidity() || el.reportValidity())) {
        this.isLoading = false;
        this.submitReady = true;
        return false;
      }

      fetch(request)
        .then((resp) => {
          el.reset();
          if (process.env.NODE_ENV === 'development') {
            console.log(`Form Handler Response: ${JSON.stringify(resp)}`);
          }
          // Handle bad form requests
          if (resp.status === 400) { this.formError = resp.body; return; }

          this.isSuccess = true;
        })
        .catch((err) => {
          this.formError = 'Encountered and internal error';
          this.isError = true;
          if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-console
            console.error(err);
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 1000);
  },
});

export default formHandler;
