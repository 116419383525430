import swiper from './swipe';

const initGallery = (galleryClass, thumbsClass) => {
  const galleryThumbs = swiper(thumbsClass, {
    spaceBetween: 10,
    slidesPerView: 4,
    loop: true,
    freeMode: true,
    loopedSlides: 5,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
  });

  swiper(galleryClass, {
    spaceBetween: 10,
    grabCursor: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    thumbs: {
      swiper: galleryThumbs,
    },
  });
};

export default initGallery;
