/* JS Entrypoint */

import 'alpinejs';
import formHandler from './modules/form';
import initGallery from './modules/gallery';
import swiper from './modules/swipe';
import scroller from './modules/scroll';

/* Global Functions for Alpine JS */
global.swiper = swiper;
global.formHandler = formHandler;
global.initGallery = initGallery;
global.scroller = scroller;
